import { dogImg, getQuoteImg, spanishDogImg } from 'App/Assets'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FinalPersonalInfo } from '../FinalPerfonalInfo'
import i18next from 'i18next'
import { appendData } from 'App/Services/Append'
import { useHistory } from 'react-router-dom'

export const GetAutoQuote = () => {
  const { getQuoteData } = useSelector(({ customer }) => customer)
  const { leadId } = useSelector(({ customer }) => customer)
  const { t } = useTranslation()
  const [openModel, setOpenModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)

  const [currentBox, setCurrentBox] = useState(2)

  const history = useHistory()

  // const handleChatModel = () => {
  //   window?._chatlio.show({
  //     expanded: true,
  //   })
  // }

  // const getQuoteViaEmail = async () => {
  //   try {
  //     const response = await fetch(
  //       `${PQA_BACKEND_API}/api/quotes/email/${getQuoteData.quoteId}`,
  //       {
  //         method: `POST`,
  //         headers: {
  //           Accept: `application/json, text/plain, */*`,
  //           'Content-Type': `application/json`,
  //         },
  //       },
  //     ).then((res) => res.json())
  //     if (!response.hasError) {
  //       Swal.fire({
  //         icon: `success`,
  //         text: `An Email has been dispatched`,
  //       })
  //     }
  //     return response
  //   } catch (err) {
  //     Swal.fire({
  //       icon: `error`,
  //       title: `Oops...`,
  //       text: `Something went wrong!`,
  //     })
  //   }
  // }

  const handleAppendData = async (price) => {
    const response = await appendData(
      {
        preferredQuote: price,
      },
      leadId,
    )
    // if (!response?.hasError) {

    // } else {
    if (response?.hasError) setErrorModal(true)
    // }
  }

  return (
    <div className="mt-16 z-0 relative">
      <div className="-mt-16 sm:mt-0">
        <div className="w-screen relative h-auto sm:h-96 flex flex-col justify-evenly items-center">
          <img
            src={getQuoteImg}
            className="absolute h-full w-full  object-cover"
          />

          <div className="flex flex-col justify-start align-center z-10">
            <div className="flex flex-col sm:flex-row sm:mt-14 gap-1 -mt-2">
              {Object.keys(getQuoteData).length < 1 ||
              getQuoteData?.offers?.length < 1 ? (
                <div className="pt-48 md:pt-0">
                  <p className="text-md sm:text-3xl text-center truncate font-semibold pb-24 md:pt-0">
                    No Offer, please contact to Agent
                  </p>
                </div>
              ) : (
                <>
                  <div className="my-auto mx-auto sm:pr-12">
                    <img
                      src={i18next.language === `en` ? dogImg : spanishDogImg}
                      className="custom-image-size"
                    />
                  </div>
                  <div>
                    <p className="text-md sm:text-3xl text-center font-bold italic">
                      {t(`getQuote.title`)}
                    </p>
                    <p className="text-center max-w-xs mx-auto sm:max-w-96">
                      <span className="text-sm">
                        {t(`getQuote.secondHeading`)}
                      </span>
                    </p>
                    <div className="grid md:flex mt-2 sm:mt-12 md:space-x-6">
                      {/* for mobile View */}
                      <div
                        onClick={() => {
                          setCurrentBox(2)
                          handleAppendData(getQuoteData?.offers[0]?.price)
                        }}
                        className={`max-w-96 my-auto mx-auto block md:hidden text-center px-8 rounded-md shadow-md bg-white ${
                          currentBox === 2
                            ? `border-4 border-red-500 w-56`
                            : `w-52`
                        }`}
                      >
                        <div className="pt-6 font-semibold">
                          <span className="border-b-4 border-red-600">
                            {t(`getQuote.firstPlan`)}
                          </span>
                        </div>
                        <div
                          className={`${
                            currentBox === 2 ? `py-4 md:py-24` : `py-3 md:py-20`
                          }`}
                        >
                          <div className="flex justify-center">
                            <span
                              className={`text-3xl md:text-5xl  ${
                                currentBox === 2 && `font-extraBold`
                              } italic`}
                            >
                              ${getQuoteData?.offers[0]?.price}
                            </span>
                            <span className="pl-2">*</span>
                          </div>
                          <p className="text-xs font-bold">
                            {t(`getQuote.per-month`)}
                          </p>
                        </div>
                        {currentBox === 2 && (
                          <button
                            className="bg-red-500 py-2 px-4 text-white  italic rounded-3xl mb-2"
                            onClick={() => setOpenModal(true)}
                          >
                            {t(`getQuote.getNow`)}
                          </button>
                        )}
                        {/* <div className='text-xs font-medium pb-2'>
                        {t(`getQuote.finalPriceMayVary`)}
                      </div> */}
                      </div>

                      <div
                        onClick={() => {
                          setCurrentBox(1)
                          handleAppendData(getQuoteData?.offers[1]?.price)
                        }}
                        className={`max-w-96 mt-2 md:mt-auto m-auto  text-center px-4 rounded-md shadow-md bg-white ${
                          currentBox === 1
                            ? `border-4 border-red-500 w-56`
                            : `w-52`
                        }`}
                      >
                        <div className="pt-2 md:pt-6 font-semibold ">
                          <span className="border-b-4 border-red-600">
                            {t(`getQuote.secondPlan`)}
                          </span>
                        </div>
                        <div
                          className={`${
                            currentBox === 1 ? `py-4 md:py-20` : `py-4 md:py-16`
                          }`}
                        >
                          <div className="flex justify-center">
                            <span
                              className={`text-2xl text-center md:text-5xl  ${
                                currentBox === 1
                                  ? `font-extraBold`
                                  : `font-medium`
                              }  italic`}
                            >
                              ${getQuoteData?.offers[1]?.price}
                            </span>
                            <span className="pl-2">*</span>
                          </div>
                          <p className="text-xs font-bold">
                            {t(`getQuote.per-month`)}
                          </p>
                        </div>
                        {currentBox === 1 && (
                          <button
                            className="bg-red-500 py-2 px-4 text-white italic rounded-3xl mb-2"
                            onClick={() => setOpenModal(true)}
                          >
                            {t(`getQuote.getNow`)}
                          </button>
                        )}
                      </div>

                      <div
                        onClick={() => {
                          setCurrentBox(2)
                          handleAppendData(getQuoteData?.offers[0]?.price)
                        }}
                        className={`max-w-96 my-auto  hidden md:block text-center px-8 rounded-md shadow-md bg-white ${
                          currentBox === 2
                            ? `border-4 border-red-500 w-56`
                            : `w-52`
                        }`}
                      >
                        <div className="pt-6 font-semibold">
                          <span className="border-b-4 border-red-600">
                            {t(`getQuote.firstPlan`)}
                          </span>
                        </div>
                        <div
                          className={`${
                            currentBox === 2 ? `py-4 md:py-24` : `py-4 md:py-20`
                          }`}
                        >
                          <div className="text-center flex">
                            <span
                              className={`text-5xl ${
                                currentBox === 2
                                  ? `font-extraBold`
                                  : `font-medium`
                              } italic`}
                            >
                              ${getQuoteData?.offers[0]?.price}
                            </span>
                            <span className="pl-2">*</span>
                          </div>
                          <p className="text-xs font-bold">
                            {t(`getQuote.per-month`)}
                          </p>
                        </div>
                        {currentBox === 2 && (
                          <button
                            className="bg-red-500 py-2 px-4 text-white italic rounded-3xl mb-2"
                            onClick={() => setOpenModal(true)}
                          >
                            {t(`getQuote.getNow`)}
                          </button>
                        )}
                        {/* <div className='text-xs font-medium pb-2'>
                        {t(`getQuote.finalPriceMayVary`)}
                      </div> */}
                      </div>

                      <div
                        onClick={() => {
                          setCurrentBox(3)
                          handleAppendData(getQuoteData?.offers[2]?.price)
                        }}
                        className={`max-w-96 mt-2 md:mt-auto m-auto  text-center px-4 rounded-md shadow-md bg-white ${
                          currentBox === 3
                            ? `border-4 border-red-500 w-56`
                            : `w-52`
                        }`}
                      >
                        <div className="pt-4 md:pt-6 font-semibold">
                          <span className="border-b-4 border-red-600">
                            {t(`getQuote.thirdPlan`)}
                          </span>
                        </div>
                        <div
                          className={`${
                            currentBox === 3 ? `py-4 md:py-20` : `py-4 md:py-16`
                          }`}
                        >
                          <div className="flex justify-center">
                            <span
                              className={`text-2xl md:text-5xl ${
                                currentBox === 3
                                  ? `font-extraBold`
                                  : `font-medium`
                              } italic`}
                            >
                              ${getQuoteData?.offers[2]?.price}
                            </span>
                            <span className="pl-2">*</span>
                          </div>
                          <p className="text-xs font-bold">
                            {t(`getQuote.per-month`)}
                          </p>
                        </div>
                        {currentBox === 3 && (
                          <button
                            className="bg-red-500 py-2 px-4 text-white italic rounded-3xl mb-2"
                            onClick={() => setOpenModal(true)}
                          >
                            {t(`getQuote.getNow`)}
                          </button>
                        )}
                        {/* <div className='text-xs font-medium pb-2'>
                        {t(`getQuote.finalPriceMayVary`)}
                      </div> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-10/12 text-center md:w-1/2 gap-2 mx-auto items-center z-10 mt-6 sm:mt-1 md:pt-36 text-xs mb-4 font-thin italic">
          {t(`getQuote.footer`)}
        </div>
      </div>

      {successModal && (
        <div className="flex text-center mx-auto">
          <div className="overflow-y-auto overflow-x-hidden mx-auto absolute z-50 top-20  md:w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 md:w-full md:mx-auto md:max-w-xs h-full mt-24 md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Quote # [{getQuoteData._leadId}]
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setOpenModal(false)
                      setSuccessModal(false)
                      history.push(`/`)
                      window.location.reload()
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.successMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <p className="text-left pt-0.5 text-sm">
                    {t(`getQuote.buttonSection`)}
                  </p>
                  <button className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl">
                    <a href="tel:(800) 639-3939">
                      {t(`getQuote.contactAgent`)}
                    </a>
                  </button>
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => handleChatModel(true)}> {t(`customQuote.chat`)}</button> */}
                  {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => {
                    setOpenModal(false)
                    setSuccessModal(false)
                    }}> Ok</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}

      {openModel && (
        <div className="flex justify-center mx-auto ">
          <div className="overflow-y-auto overflow-x-hidden mx-auto absolute top-20 z-50 md:w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 md:w-full max-w-xs md:mx-auto h-full md:h-auto">
              <FinalPersonalInfo
                setErrorModal={() => setErrorModal(true)}
                closeModal={() => {
                  setOpenModal(false)
                  setSuccessModal(true)
                }}
              />
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700"></div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}

      {errorModal && (
        <div className="flex justify-center text-center mx-auto">
          <div className="overflow-y-auto overflow-x-hidden mx-auto absolute top-40 md:top-28 z-50 md:w-full  md:inset-0 h-modal md:h-full">
            <div className="relative p-4 md:w-full md:max-w-xs mx-auto h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                  <p className="px-1 text-md font-semibold  text-white">
                    Quote # [{getQuoteData._leadId}]
                  </p>
                  <button
                    className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setOpenModal(false)
                      setErrorModal(false)
                    }}
                  ></button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="px-6 py-4 space-y-6">
                  <p className="text-center font-medium">
                    {t(`getQuote.errorMessage`)}
                  </p>
                </div>
                <hr />
                <div className="flex text-center py-2 space-x-2 justify-center">
                  <button
                    className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl"
                    onClick={() => {
                      setErrorModal(false)
                      setOpenModal(false)
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </div>
  )
}
