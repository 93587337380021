/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import {
  setActiveCustomTab,
  setCurrentCustom,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { appendData } from 'App/Services/Append'
import { Life } from 'App/Assets'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { useTranslation } from 'react-i18next'
import { Radio } from 'App/Components/Common/RadioButton';

export const DoYouSmoke = ({ activeTab }) => {
  const { customer } = useSelector(({ custom }) => custom)

  const { leadId } = useSelector(({ customer }) => customer)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const [errors, setErrors] = useState({
    doYouSmokeError: ``,
    formSubmit: false,
  })
  
  const dispatch = useDispatch()

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData(
      {
        doYouSmoke: customer?.doYouSmoke
      },
      leadId,
    )
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setActiveCustomTab(activeTab + 1))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  useEffect(() => {
    if (errors.formSubmit && !errors.doYouSmokeError) {
      handleAppendData()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className="flex sm:justify-center sm:items-center ">
      <div className="bg-form-gray sm:shadow-lg rounded-md sm:py-2 px-6 md:px-10">
        <img src={Life} alt="Image Not Found" className="mx-auto w-20" />

        <div className="flex-1 ">
          <h2 className="sm:text-md text-center  w-full mx-auto py-2 sm:py-1">
            {t(`customLife.doYouSmoke`)}
          </h2>
        </div>

        <div className="flex flex-col  w-full mx-auto sm:flex-row sm:w-auto sm:items-center sm:gap-6 pt-2 sm:pt-0">
          <div className="flex flex-col mx-auto relative">
            <Radio
              value="Yes"
              id="employed"
              name="doYouSmoke"
              button={true}
              buttonLabel={t(`customLife.yes`)}
              setValue={(e) => handleCustomerChange(`doYouSmoke`, e.target.value)}
              selected={customer?.doYouSmoke}
            />
            <Radio
              value="No"
              id="unemployed"
              name="doYouSmoke"
              button={true}
              buttonLabel={t(`customLife.no`)}
              setValue={(e) => handleCustomerChange(`doYouSmoke`, e.target.value)}
              selected={customer?.doYouSmoke}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-1 pt-6">
            {/* <SubmitButton
              onClick={() => dispatch(setActiveCustomerTab(activeTab - 1))}
            >
              <span className="pr-3">
                <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
              </span>
              {t(`isEmployed.previous`)}
            </SubmitButton> */}
          </div>
          <div className="flex-1"></div>
          <div className="flex-1 pt-6">
            <SubmitButton
              onClick={() => {
                handleAppendData()
              }}
              disabled={!customer?.doYouSmoke}
            >
              {t(`customLife.claimQuote`)}
              <span className="pl-3">
                {!loading ? (
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                ) : (
                  <Loader />
                )}
              </span>
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  )
}
