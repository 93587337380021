import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
// import moment from 'moment'

const initialState = {
  leadId: ``,
  openModal: false,
  customerAdd: true,
  activeTabCustomer: 1,
  ref: { firstName: ``, lastName: ``, middleName: ``, phone: `` },
  phone: ``,
  customer: {
    // email: ``,
    phone: ``,
    // channel: `SMS`,
    // maritalStatus: ``,
    // education: ``,
    // isEmployed: ``,
    // homeOwner: ``,
    // accidentStatus: ``,
    firstName: ``,
    lastName: ``,
    dob: new Date(moment().subtract(16, `years`)),
    // gender: ``,
    // inputMethod: ``,
    // referrals: ``,
    address: ``,
    ip: ``,
    // preferredContactMethods: [],
  },
  prospectType: ``,
  // licenseData: {},
  getQuoteData: {},
}

const customerSlice = createSlice({
  name: `customer`,

  initialState,
  reducers: {
    setLeadId: (state, { payload }) => {
      state.leadId = payload
    },
    setLicenseData: (state, { payload }) => {
      state.licenseData = payload
    },
    setAutoQuote: (state, { payload }) => {
      state.getQuoteData = payload
    },
    setProspectType: (state, { payload }) => {
      state.prospectType = payload
    },
    setActiveCustomerTab: (state, { payload }) => {
      state.activeTabCustomer = payload
    },
    setRefCustomer: (state, { payload }) => {
      state.ref[payload?.name] = payload.value
    },
    setOpenModal: (state, { payload }) => {
      state.openModal = payload
    },
    setAddCustomer: (state, { payload }) => {
      state.customerAdd = payload
    },
    setCurrentPhone: (state, { payload }) => {
      state.phone = payload
    },
    setCurrentCustomer: (state, { payload }) => {
      state.customer[payload?.name] = payload?.value
    },
  },
})

export const {
  setActiveCustomerTab,
  setCurrentCustomer,
  setOpenModal,
  setCustomerPhone,
  setAddCustomer,
  setRefCustomer,
  setLeadId,
  setProspectType,
  setLicenseData,
  setAutoQuote,
} = customerSlice.actions

export const customer = customerSlice.reducer
