import { NotFound } from 'App/Components/404'
import { AutoFormPage } from 'App/Pages/AutoForm'
import { CustomFormPage } from 'App/Pages/CustomFormPage'
import { GetQuotePageCustom } from 'App/Pages/GetQuotePage'
import { GetQuotePageAuto } from 'App/Pages/GetQuotePageAuto'
import { HomePage } from 'App/Pages/MainPage'

import React from 'react'
import { Redirect } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'

export const Router = () => {
  // useEffect(() => {
  //   if (window?.innerWidth <= 600) {
  //     window.location.replace(
  //       `https://www.m.veronicasquote.com/${window.location.search}`,
  //     )
  //   }
  // }, [])

  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/auto">
        <AutoFormPage />
      </Route>
      <Route exact path="/custom">
        <CustomFormPage />
      </Route>
      <Route exact path="/custom/quote-reference">
        <GetQuotePageCustom />
      </Route>
      <Route exact path="/auto/get-a-quote">
        <GetQuotePageAuto />
      </Route>
      <Route path="/404">
        <NotFound />
      </Route>
      <Route path="*" render={() => <Redirect to="/404" />} />
    </Switch>
  )
}
