/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { setCurrentVehicle, setActiveVehicleTab } from 'App/Redux/actions'
// import { useSelector } from 'react-redux'
import { loadMakeYears } from 'App/Services/Vehicles'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Car1, vehicleScreen } from 'App/Assets'
import { PQA_BACKEND_API } from 'App/Config'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { appendData } from 'App/Services/Append'
import { setActiveCustomerTab } from 'App/Redux/actions'
import {
  customer,
  setAutoQuote,
  setCurrentCustomer,
} from 'App/Redux/slices/customer'
import Swal from 'sweetalert2'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory } from 'react-router-dom'
export const VehicleMake = ({ activeTab }) => {
  const { vehicle } = useSelector(({ vehicle }) => vehicle)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { customer, prospectType, leadId } = useSelector(
    ({ customer }) => customer,
  )
  const [make, setMake] = useState(``)
  const [year, setYear] = useState(``)
  const [model, setModel] = useState(``)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  useEffect(() => {
    if (vehicle.model.length > 0) {
      setMake({ value: vehicle.make, label: vehicle.make })
      setYear({ value: vehicle.year, label: vehicle.year })
      setModel({ value: vehicle.model, label: vehicle.model })
    }

    return () => {
      setMake(``)
      setYear(``)
      setModel(``)
    }
  }, [vehicle])

  const analyzeCar = async (data) => {
    const response = await fetch(`${PQA_BACKEND_API}/api/analyze/car`, {
      method: `POST`,
      headers: {
        Accept: `application/json, text/plain, */*`,
        'Content-Type': `application/json`,
      },
      body: JSON.stringify({
        ...data,
      }),
    }).then((res) => res.json())
    dispatch(setCurrentVehicle({ name: `vin`, value: response?.carData?.vin }))
    return response
  }

  const loadMake = async (search, loadedOptions, { page }) => {
    const response = await fetch(
      `${PQA_BACKEND_API}/api/get/makes/${year.value}`,
    ).then((res) => res.json())

    const formattedMakeYears = response.makes?.map((make) => {
      return {
        value: make,
        label: make,
      }
    })
    return {
      options: formattedMakeYears,
      hasMore: response.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadModel = async (search, loadedOptions, { page }) => {
    const response = await fetch(
      `${PQA_BACKEND_API}/api/get/models/${year.value}/${make.value}`,
    ).then((res) => res.json())

    const formattedMakeYears = response.models?.map((make) => {
      return {
        value: make,
        label: make,
      }
    })
    return {
      options: formattedMakeYears,
      hasMore: response.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleAppendData = async () => {
    setLoading(true)
    const response = await appendData({
      ymm: {
        year: year.value,
        make: make.value,
        model: model.value,
      },
      leadId,
    })
    if (!response?.hasError) {
      setLoading(false)
      dispatch(setActiveCustomerTab(activeTab + 1))
    } else {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  const payload = () => {
    let quoteData = {
      cars: [
        {
          make: make.value,
          model: model.value,
          year: year.value,
        },
      ],
      customer: {
        customerData: customer,
        //   {
        //         "phone": customer.phone,
        //         "firstName": customer.firstName,
        //         "lastName": customer.lastName,
        //         "address": address,
        //         "ip": customer.ip,
        //         "email": customer.email,
        //         "dob":customer.dob,
        //         "zip":customer.address.zip
        // }
        // customerIncidents: [{ isGuilty: customer?.accidentStatus }],
      },
      // inputMethod: customer?.inputMethod,
      ip: customer?.ip,
      prospectId: leadId,
      quoteSource: `WEB`,
      quoteType: prospectType,
      // ref: ref.firstName ? ref : null,
    }

    return { ...quoteData }
  }

  const generateQuote = async (data) => {
    setLoading(true)
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/generate`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({ ...data }),
      }).then((res) => {
        setLoading(false)
        return res.json()
      })
      if (response?.hasError) {
        setLoading(false)
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      } else {
        setLoading(false)
        dispatch(setAutoQuote(response?.quote))
        dispatch(setActiveCustomerTab(1))
        dispatch(setCurrentCustomer({ name: ``, value: `` }))

        history.push(`/auto/get-a-quote`)
      }
    } catch (err) {
      setLoading(false)
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  return (
    <>
      <Spinner loading={loading} />
      <div
        className={`flex  sm:justify-center sm:items-center ${
          loading && `opacity-30 pointer-events-none`
        }`}
      >
        <div className=" bg-form-gray sm:shadow-lg rounded-md mx-2 py-4 sm:py-6 px-6 sm:px-8">
          <img
            src={vehicleScreen}
            alt="Image Not Found"
            className="mx-auto h-14 w-16"
          />

          <div className="flex-1 mt-3">
            <h2 className="sm:text-md text-center w-full mx-auto">
              {t(`Year.title`)}
            </h2>
          </div>

          <div className="pt-2">
            <div className='flex flex-col w-80 mx-auto sm:w-96 gap-2 relative"'>
              <div className="relative">
                <ReactSelectField
                  name="year"
                  placeholder={t(`Year.year`)}
                  loadOptions={loadMakeYears}
                  setValue={(item) => {
                    setYear(item)
                  }}
                  value={year}
                  isMulti={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  name="make"
                  placeholder={t(`Year.make`)}
                  loadOptions={year.value && loadMake}
                  setValue={(item) => {
                    setMake(item)
                  }}
                  disabled={!year.value}
                  isSearchable={false}
                  value={make}
                  cacheUniqs={year.value}
                  isMulti={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  name="model"
                  placeholder={t(`Year.model`)}
                  loadOptions={year.value && make.value && loadModel}
                  setValue={(item) => {
                    setModel(item)
                  }}
                  disabled={!year?.value}
                  value={model}
                  cacheUniqs={make.value}
                  isMulti={false}
                />
              </div>
            </div>
          </div>

          <div className="flex pt-6 px-1 sm:pb-0 gap-4">
            <div className="flex-1 ">
              <SubmitButton
                onClick={() => {
                  dispatch(setActiveCustomerTab(activeTab - 1))
                }}
              >
                <span className="pr-3">
                  <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
                </span>
                {t(`Year.previous`)}
              </SubmitButton>
            </div>

            <div className="flex-1"></div>
            <div className="flex-1"></div>
            <div className="flex-1 ">
              <SubmitButton
                onClick={() => {
                  setYear(``)
                  setMake(``)
                  setModel(``)
                  dispatch(
                    setCurrentVehicle({
                      name: `year`,
                      value: year.value,
                    }),
                  )
                  dispatch(
                    setCurrentVehicle({
                      name: `make`,
                      value: make.value,
                    }),
                  )
                  dispatch(
                    setCurrentVehicle({
                      name: `model`,
                      value: model.value,
                    }),
                  )
                  generateQuote(payload())
                }}
                disabled={!model.value}
              >
                {t(`freeQuote`)}
                <span className="pl-3">
                  <FontAwesomeIcon icon={faChevronRight} className="text-md" />
                </span>
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
