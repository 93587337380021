import {
  Bike,
  Boat,
  Building,
  Car,
  Health,
  homeImage,
  House,
  Life,
  PET,
  Renter,
} from 'App/Assets'

import { useTranslation } from 'react-i18next'

import { setGlobalProspect, setProspectType } from 'App/Redux/actions'

import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

export const HomeScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <>
      <div className="text-center custom-font-size  italic font-extrabold mb-2">
        {t(`HOME.mainHeading`)}
      </div>
      <div className="text-center text-xs sm:text-md italic font-semibold mb-2 max-w-xs mx-auto sm:max-w-none">
        {t(`HOME.subHeading`)}
      </div>
      <div className="flex  sm:px-48 overflow-y-scroll sm:overflow-hidden h-screen pb-8 sm:pb-0 sm:h-auto">
        <div>
          <img
            className="cursor-pointer hidden sm:block"
            src={homeImage}
            alt="home image"
          />
        </div>
        <div className="grid sm:w-2/3 md:w-3/4 sm:mx-auto grid-cols-2 md:grid-cols-3 mx-auto  px-4 lg:mt-12 mt-4">
          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4 md:mx-0 group-hover:scale-50">
            <div className="">
              <Link
                to={`/auto`}
                onClick={() => {
                  dispatch(setProspectType(`AUTO`))
                  dispatch(setGlobalProspect(`AUTO`))
                }}
              >
                <img
                  src={Car}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.auto`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`LIFE`))
                  dispatch(setProspectType(`LIFE`))
                }}
              >
                <img
                  src={Life}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.life`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`HOME`))
                  dispatch(setProspectType(`HOME`))
                }}
              >
                <img
                  src={House}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.home`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`COMMERCIAL`))
                  dispatch(setProspectType(`COMMERCIAL`))
                }}
              >
                <img
                  src={Building}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.commercial`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`HEALTH`))
                  dispatch(setProspectType(`HEALTH`))
                }}
              >
                <img
                  src={Health}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.health`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`MOTORCYCLE`))
                  dispatch(setProspectType(`MOTORCYCLE`))
                }}
              >
                <img
                  src={Bike}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3 ">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.motorcycle`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`RENTERS`))
                  dispatch(setProspectType(`RENTERS`))
                }}
              >
                <img
                  src={Renter}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.renters`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`BOAT`))
                  dispatch(setProspectType(`BOAT`))
                }}
              >
                <img
                  src={Boat}
                  alt="Image not loaded"
                  width={100}
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.boat`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>

          <div className="text-black flex flex-col rounded-md items-center py-2 mx-4 sm:mx-1 sm:px-4">
            <div className="">
              <Link
                to={`/custom`}
                onClick={() => {
                  dispatch(setGlobalProspect(`PET`))
                  dispatch(setProspectType(`PET`))
                }}
              >
                <img
                  src={PET}
                  alt="Image not loaded"
                  className="mx-auto h-16 w-16"
                />
                <div className="text-center mt-3">
                  <h6 className="sm:px-2 px-4 text-sm font-semibold">
                    {t(`DEFAULT.pet`)}
                  </h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-md bg-gray-100 absolute w-full custom-botton h-20">
        <div className="">
          <p className="pt-1">{t(`HOME.footerHeading`)}</p>
          <button className="bg-red-600 py-1.5 px-8 text-white text-md rounded-3xl italic mt-2">
            <a href="tel:(800) 639-3939">{t(`HOME.callNow`)}</a>
          </button>
        </div>
      </div>
    </>
  )
}
