import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider as ReduxProvider } from 'react-redux'
import { store, persistor } from './App/Redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-notifications/lib/notifications.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { MainLayout } from 'App/HOC/MainLayout'
import { Router } from 'App/Router'
// import { I18nextProvider } from 'react-i18next'
// import i18n from 'i18n/i18n'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const queryClient = new QueryClient()

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: `en`,
    supportedLngs: [`en`, `sp`],
    fallbackLng: `en`,
    debug: false,
    // Options for language detector
    detection: {
      order: [`path`, `cookie`, `htmlTag`],
      caches: [`cookie`],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json`,
    },
  })

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <React.StrictMode>
            {/* <I18nextProvider i18n={i18n}> */}
            <MainLayout>
              <Router />
            </MainLayout>
            {/* </I18nextProvider> */}
          </React.StrictMode>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  // <Home />,
  document.getElementById(`root`),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
reportWebVitals()
