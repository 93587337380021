import { PQA_BACKEND_API } from 'App/Config'

export const loadMakeYears = async () => {
  const response = await fetch(`${PQA_BACKEND_API}/api/get/modelyears`).then(
    (res) => res.json(),
  )
  if (response?.modelYears) {
    const formattedMakeYears = response?.modelYears?.map((year) => {
      return {
        value: year,
        label: year,
      }
    })
    return {
      options: formattedMakeYears,
      hasMore: false,
    }
  }
}
