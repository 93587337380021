import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  addVehicle: false,
  activeVehicleTab: 1,
  vehicle: {
    vehicleInformation: ``,
    dailyMileage: ``,
    financeMode: ``,
    coverage: ``,
    additionalCover: [],
    vin: ``,
    year: ``,
    model: ``,
    make: ``,
  },
  cars: [],
}

const vehicleSlice = createSlice({
  name: `vehicle`,

  initialState,
  reducers: {
    setCurrentVehicle: (state, { payload }) => {
      state.vehicle[payload.name] = payload.value
    },
    setAdditionalCover: (state, { payload }) => {
      state.vehicle.additionalCover.push(payload)
    },
    setActiveVehicleTab: (state, { payload }) => {
      state.activeVehicleTab = payload
    },
    addVehicles: (state, { payload }) => {
      state.cars.push(payload)
    },
    openAddVehicle: (state, { payload }) => {
      state.addVehicle = payload
    },
    setVehicleInitialState: (state) => {
      state.vehicle = {
        vehicleInformation: ``,
        dailyMileage: ``,
        financeMode: ``,
        coverage: ``,
        additionalCover: [],
        vin: ``,
        year: ``,
        model: ``,
        make: ``,
      }
    },
  },
})

export const {
  setCurrentVehicle,
  addVehicles,
  openAddVehicle,
  setActiveVehicleTab,
  setVehicleInitialState,
  setAdditionalCover,
} = vehicleSlice.actions

export const vehicle = vehicleSlice.reducer
