/* eslint-disable react/prop-types */
import { Layout } from 'App/Components/Layout'
import { PQA_BACKEND_API } from 'App/Config'
import { setCurrentCustomer, setLeadId } from 'App/Redux/actions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'

export const MainLayout = ({ children }) => {
  const dispatch = useDispatch()
  const search = useLocation().search

  const carDealer = new URLSearchParams(search).get(`carDealer`)
  const creator = new URLSearchParams(search).get(`creator`)
  const createProspect = async (data) => {
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/add`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }).then((res) => res.json())

      if (!response?.hasError) {
        dispatch(setLeadId(response?.data?.leadId))
      } else {
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      }
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  // const [leadId, setLeadId] = useState(``)
  useEffect(() => {
    try {
      fetch(`https://jsonip.com`, { mode: `cors` })
        .then((resp) => resp.json())
        .then(({ ip }) => {
          dispatch(setCurrentCustomer({ name: `ip`, value: ip }))
          createProspect({
            ip,
            source: `WEB`,
            ...(carDealer ? { _carDealer: carDealer, _creator: creator } : {}),
          })
        })
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }, [])
  return <Layout>{children}</Layout>
}
