import tw from 'tailwind-styled-components'

export const TOne = tw.div`
    ${({ opentab }) => (opentab === 1 ? `block` : `hidden`)}
`
export const TTwo = tw.div`
    ${({ opentab }) => (opentab === 2 ? `block` : `hidden`)}
`
export const TThree = tw.div`
    ${({ opentab }) => (opentab === 3 ? `block` : `hidden`)}
`
export const TFour = tw.div`
    ${({ opentab }) => (opentab === 4 ? `block` : `hidden`)}
`
export const TFive = tw.div`
    ${({ opentab }) => (opentab === 5 ? `block` : `hidden`)}
`
export const TSix = tw.div`
    ${({ opentab }) => (opentab === 6 ? `block` : `hidden`)}
`
export const TSeven = tw.div`
    ${({ opentab }) => (opentab === 7 ? `block` : `hidden`)}
`
export const TEight = tw.div`
    ${({ opentab }) => (opentab === 8 ? `block` : `hidden`)}
`
export const TNine = tw.div`
    ${({ opentab }) => (opentab === 9 ? `block` : `hidden`)}
`
export const TTen = tw.div`
    ${({ opentab }) => (opentab === 10 ? `block` : `hidden`)}
`
export const TEleven = tw.div`
    ${({ opentab }) => (opentab === 11 ? `block` : `hidden`)}
`
export const TTwelve = tw.div`
    ${({ opentab }) => (opentab === 12 ? `block` : `hidden`)}
`
export const TThirteen = tw.div`
    ${({ opentab }) => (opentab === 13 ? `block` : `hidden`)}
`
export const TFourteen = tw.div`
    ${({ opentab }) => (opentab === 14 ? `block` : `hidden`)}
`
export const TFifteen = tw.div`
    ${({ opentab }) => (opentab === 15 ? `block` : `hidden`)}
`
export const GetQuote = tw.div`
    ${({ getQuote, opentab }) =>
      getQuote && opentab === 4 ? `block` : `hidden`}
`
export const OpenDriverForm = tw.div`
    ${({ opentab }) => (opentab === true ? `block` : `hidden`)}
`
