/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { SubmitButton } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  setActiveCustomerTab,
  setActiveCustomTab,
  setCurrentCustom,
  setLeadId,
  setOpenModalCustom,
  setQuoteResult,
} from 'App/Redux/actions'
import { MaskField } from 'App/Components/Common/MaskField'

import { getOtp } from 'App/Services/OTP/getOtp'
import { InputField } from 'App/Components/Common/InputField'
import { Radio } from 'App/Components/Common/RadioButton'
import { regEx } from 'App/Services'
import { Contact, contactCard } from 'App/Assets'
import { useTranslation } from 'react-i18next'
import { appendData } from 'App/Services/Append'
import Swal from 'sweetalert2'
import { Loader } from 'App/Components/Common/Loader'
import { PQA_BACKEND_API } from 'App/Config'

export const CustomContactInfo = ({ activeTab }) => {
  const { customer, address } = useSelector(({ custom }) => custom)

  const {
    leadId,
    prospectType,
    customer: currentCustomer,
  } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [quoteData, setQuoteData] = useState(``)

  const [errors, setErrors] = useState({
    // emailError: ``,
    firstNameError: ``,
    lastNameError: ``,
    phoneError: ``,
    channelError: ``,
    phoneVerifiedError: ``,
    formSubmit: false,
  })
  const history = useHistory()

  const handleErrors = (name, value) => {
    setErrors((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    // if (!customer.email.length) {
    //   handleErrors(`emailError`, `Enter Email`)
    // } else if (customer.email.length) {
    //   if (!regEx.test(customer.email.toLowerCase())) {
    //     handleErrors(`emailError`, `Enter Valid Email`)
    //   } else {
    //     handleErrors(`emailError`, ``)
    //   }
    // } else {
    //   handleErrors(`emailError`, ``)
    // }

    if (!customer?.firstName?.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }
    if (!customer?.lastName?.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!customer.phone.length || customer.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else handleErrors(`phoneError`, ``)

    // if (!customer.channel.length) {
    //   handleErrors(`channelError`, `Select Verification Method`)
    // } else handleErrors(`channelError`, ``)

    handleErrors(`formSubmit`, true)
  }

  const handleCustomerChange = (name, value) => {
    dispatch(
      setCurrentCustom({
        name,
        value,
      }),
    )
  }

  const preparePayload = () => {
    let data = {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phone: customer?.phone,
      quoteType: prospectType,
    }

    return data
  }

  const handlePayload = async () => {
    const response = await appendData(preparePayload(), leadId)
    if (!response?.hasError) {
      setLoading(false)
      // dispatch(setQuoteResult(response?.quote))
      setQuoteData(response?.data)
      setSuccessModal(true)
      // history.push(`/custom/quote-reference`)
    } else {
      setLoading(false)
      setErrorModal(true)
      // history.push(`/`)
      // Swal.fire({
      //   icon: `error`,
      //   title: `Oops...`,
      //   text: `Something went wrong!`,
      // }).then(() => history.push(`/`))
    }
  }

  const createProspect = async (data) => {
    try {
      const response = await fetch(`${PQA_BACKEND_API}/api/leads/add`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          ip: currentCustomer?.ip,
          source: `WEB`,
        }),
      }).then((res) => res.json())

      if (!response?.hasError) {
        dispatch(setLeadId(response?.data?.leadId))
      } else {
        Swal.fire({
          icon: `error`,
          title: `Oops...`,
          text: `Something went wrong!`,
        })
      }
    } catch (err) {
      Swal.fire({
        icon: `error`,
        title: `Oops...`,
        text: `Something went wrong!`,
      })
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError &&
      !errors.lastNameError &&
      // !errors.emailError &&
      !errors.phoneError &&
      !errors.phoneVerifiedError
      // !errors.channelError
    ) {
      setLoading(true)

      // getOtp({
      //   channel: `SMS`,
      //   phoneNo: customer.phone,
      // })

      // dispatch(setOpenModalCustom(true))
      handlePayload()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  // const handlePhoneValidation = useDebouncedCallback(async (value) => {
  //   const response = await validatePhone(value)
  //   if (response.hasError) {
  //     handleErrors(`formSubmit`, false)
  //     handleErrors(`phoneVerifiedError`, `Invalid Phone`)
  //   } else {
  //     handleErrors(`formSubmit`, false)
  //     handleErrors(`phoneVerifiedError`, ``)
  //   }
  // }, 1000)

  return (
    <div className="flex sm:justify-center sm:items-center my-auto">
      <div className="bg-form-gray sm:shadow-lg rounded-md py-2 px-4 sm:py-6 sm:px-10">
        <img
          src={contactCard}
          alt="Image Not Found"
          className="mx-auto h-14 w-16"
        />
        <div className="flex-1">
          <h2 className="sm:text-md text-center w-full mx-auto py-2 sm:py-4">
            {t(`customForm.contactInfo`)}
          </h2>
        </div>

        <div className="flex flex-col gap-2 w-80 mx-auto  sm:w-96 sm:items-center sm:gap-6 pt-2 sm:pt-4">
          {/* <div className="flex-1 relative ">
            <InputField
              title={t(`ContactInfo.email`)}
              type="email"
              name="email"
              errorMessage={errors?.emailError}
              setValue={(e) => handleCustomerChange(`email`, e.target.value)}
              placeholder={t(`ContactInfo.email`)}
              value={customer?.email}
            />
          </div> */}
          <div className="flex-1 relative w-350">
            {/* <div className='text-gray-700 font-medium text-sm'>
              {t(`customForm.firstName`)}
            </div> */}
            <InputField
              title={t(`customForm.firstName`)}
              type="text"
              name="firstName"
              errorMessage={errors?.firstNameError}
              setValue={(e) => {
                handleCustomerChange(`firstName`, e.target.value)
              }}
              placeholder={t(`customForm.firstName`)}
              value={customer?.firstName}
            />
          </div>
          <div className="flex-1 relative w-350">
            {/* <div className='text-gray-700 font-medium text-sm'>
            {t(`customForm.lastName`)}
            </div> */}
            <InputField
              title={t(`customForm.lastName`)}
              type="text"
              errorMessage={errors?.lastNameError}
              setValue={(e) => {
                handleCustomerChange(`lastName`, e.target.value)
              }}
              placeholder={t(`customForm.lastName`)}
              value={customer?.lastName}
            />
          </div>
          <div className="flex-1 relative w-350">
            <MaskField
              title={t(`customLife.preferredNumber`)}
              name="phone"
              errorMessage={errors?.phoneError || errors.phoneVerifiedError}
              setValue={(e) => {
                handleCustomerChange(`phone`, e.target.value)
                // if (!customer?.phone && customer?.phone !== `+1`)
                //   handlePhoneValidation(e.target.value)
              }}
              placeholder={t(`customLife.preferredNumber`)}
              value={customer?.phone}
            />
          </div>
        </div>
        {/* <div className="flex flex-col gap-2 w-full mx-auto sm:flex-row sm:w-auto sm:items-center sm:gap-6 pt-6 sm:pt-4">
          <div className="flex gap-6 relative">
            <Radio
              label="Call"
              errorMessage={errors.channelError}
              name="verification"
              id="call"
              value="CALL"
              setValue={(e) => handleCustomerChange(`channel`, e.target.value)}
            />
            <Radio
              label="SMS "
              errorMessage={errors.channelError}
              name="verification"
              id="SMS"
              value="SMS"
              setValue={(e) => handleCustomerChange(`channel`, e.target.value)}
            />
            {errors?.channelError && (
              <>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="absolute text-red-500 -right-16 mt-4"
                />
                <p className="absolute text-sm text-red-500 -bottom-5 mb-1">
                  {errors?.genderError}
                </p>
              </>
            )}
          </div>
        </div> */}
        <div className="flex mt-5 pt-10 sm:p-1 gap-10 mx-3">
          <div className="flex-1">
            <SubmitButton
              onClick={() => dispatch(setActiveCustomTab(activeTab - 1))}
            >
              <span className="pr-3">
                <FontAwesomeIcon icon={faChevronLeft} className="text-md" />
              </span>
              {t(`Address.previous`)}
            </SubmitButton>
          </div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>

          <div className="flex-1 pb-8">
            <SubmitButton
              onClick={() => {
                validateForm()
              }}
            >
              {/* {t(`Referring.getQuote`)} */}
              {t(`customForm.submit`)}
              <span className="pl-3">{loading && <Loader />}</span>
            </SubmitButton>
          </div>
        </div>

        {errorModal && (
          <div className="absolute flex text-center mx-auto -mt-60 md:-mt-96 md:ml-10">
            <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full md:inset-0 h-modal md:h-full">
              <div className="relative p-4 w-full max-w-xs h-full md:h-auto">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* <!-- Modal header --> */}
                  <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                    <p className="px-1 text-md font-semibold  text-white">
                      Error
                    </p>
                    <button
                      className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => {
                        setErrorModal(false)
                      }}
                    ></button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="px-6 py-4 space-y-6">
                    <p className="text-center font-medium">
                      {t(`getQuote.errorMessage`)}
                    </p>
                  </div>
                  <hr />
                  <div className="flex text-center py-2 space-x-2 justify-center">
                    <button
                      className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl"
                      onClick={() => {
                        setErrorModal(false)
                      }}
                    >
                      {` `}
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        )}

        {successModal && (
          <div className="absolute flex text-center mx-auto -mt-60 md:-mt-96 md:ml-10">
            <div className="overflow-y-auto overflow-x-hidden mx-auto z-50 w-full md:inset-0 h-modal md:h-full">
              <div className="relative p-4 w-full max-w-xs h-full md:h-auto">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* <!-- Modal header --> */}
                  <div className="flex justify-between items-start p-2 rounded-t border-b dark:border-gray-600 bg-black">
                    <p className="px-1 text-md font-semibold  text-white">
                      Quote # [{quoteData?._leadId}]
                    </p>
                    <button
                      className="text-gray-200 bg-red-400 hover:bg-red-200 hover:text-gray-900 rounded-lg text-sm mt-1.5 p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={() => {
                        setSuccessModal(false)
                        createProspect()
                        dispatch(setActiveCustomerTab(1))
                        dispatch(setActiveCustomTab(1))
                        handleCustomerChange(`firstName`, ``)
                        handleCustomerChange(`lastName`, ``)
                        handleCustomerChange(`phone`, ``)
                        history.push(`/`)
                      }}
                    ></button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="px-6 py-4 space-y-6">
                    <p className="text-center font-medium">
                      {t(`getQuote.successMessage`)}
                    </p>
                  </div>
                  <hr />
                  <div className="flex text-center py-2 space-x-2 justify-center">
                    <p className="text-left pt-0.5 text-sm">
                      {t(`getQuote.buttonSection`)}
                    </p>
                    <button className="bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl">
                      <a href="tel:(800) 639-3939">
                        {t(`getQuote.contactAgent`)}
                      </a>
                    </button>
                    {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => handleChatModel(true)}> {t(`customQuote.chat`)}</button> */}
                    {/* <button className='bg-red-500 py-0.5 px-4 text-white text-sm rounded-3xl' onClick={() => {
                    setOpenModal(false)
                    setSuccessModal(false)
                    }}> Ok</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        )}
      </div>
    </div>
  )
}
