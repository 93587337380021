import React from 'react'
import { VehicleMake } from './VehicleMake'
import { useSelector } from 'react-redux'
import { TOne, TTwo } from '../Layout/style'
import { GetInitialInfo } from './GetInitialInfo'

export const AutoForm = () => {
  const { activeTabCustomer } = useSelector(({ customer }) => customer)

  return (
      <div className='my-auto mt-12'>
        <TOne opentab={activeTabCustomer}>
          <GetInitialInfo activeTab={activeTabCustomer}/>
        </TOne>

        <TTwo opentab={activeTabCustomer}>
          <VehicleMake activeTab={activeTabCustomer} />
        </TTwo>
      </div>
  )
}
