/* eslint-disable no-unused-vars */
import React from 'react'
import { CustomContactInfo } from './CustomContactInfo'
import { useSelector } from 'react-redux'
import { TOne, TThree, TTwo } from 'App/Components/Layout/style'
import { CustomPersoanlInfo } from './CustomPersoanlInfo'
import { DoYouSmoke } from './LifeForm/doYouSmoke'
import { PersonalInfo } from './LifeForm/personalInfo'
import { ContactInfo } from './LifeForm/contactInfo'
import { PetInfo } from './PetForm/petInfo'
import { PetFormPersonalInfo } from './PetForm/personalInfo'

export const CustomForm = () => {
  const { activeTabCustom } = useSelector(({ custom }) => custom)
  const { prospect } = useSelector(({ global }) => global)

  if (prospect == `PET`) {
    return (
      <div className="my-auto mt-12">
        <TOne opentab={activeTabCustom}>
          <PetInfo activeTab={activeTabCustom} />
        </TOne>
        <TTwo opentab={activeTabCustom}>
          <PetFormPersonalInfo activeTab={activeTabCustom} />
        </TTwo>
      </div>
    )
  } else if (prospect == `LIFE`) {
    return (
      <div className="my-auto mt-12">
        <TOne opentab={activeTabCustom}>
          <DoYouSmoke activeTab={activeTabCustom} />
        </TOne>

        <TTwo opentab={activeTabCustom}>
          <PersonalInfo activeTab={activeTabCustom} />
        </TTwo>

        {/* <TThree opentab={activeTabCustom}>
          <ContactInfo activeTab={activeTabCustom} />
        </TThree> */}
      </div>
    )
  } else {
    return (
      <div className="my-auto mt-12">
        <TOne opentab={activeTabCustom}>
          <CustomPersoanlInfo activeTab={activeTabCustom} />
        </TOne>
        <TTwo opentab={activeTabCustom}>
          <CustomContactInfo activeTab={activeTabCustom} />
        </TTwo>
      </div>
    )
  }
}
